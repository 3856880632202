import React, {Ref, useEffect, useRef, useState} from 'react';
import ConfirmPickUpRender from './ConfirmPickUpRender';
import useOnClickOut from 'hooks/useOnClickOut';

interface CategoryPopupProps{
	setHidden:()=>void
}
export const ConfirmPickUp = ({setHidden}:CategoryPopupProps):JSX.Element=>{
	const formRef:Ref<Element> = useRef(null);
	let baseValue:number = 18.99;
	const [total,setTotal]:any = useState('');
	const [tip,setTip]:any = useState('');
	const [submitError,setSubmitError]:any = useState('');
	const [submitLoading,setSubmitLoading]:any = useState('');
	useOnClickOut(formRef,setHidden);

	useEffect(():void=>{
		setTotal(baseValue+tip);
	},[tip]);

	const payOrder= (data:any):void=>{
		console.log('Pay Order',{data});
	};

	const SetTip = (newTip:number):void=> setTip(newTip);

	return <ConfirmPickUpRender
		formRef = {formRef}
		submitLoading={submitLoading}
		errors={{submitError}}
		total={total}

		hidePopup={setHidden}
		setTip={SetTip}
		onSubmit={payOrder}
	/>;
};

