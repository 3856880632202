import React, { useContext} from 'react';
import {Navigate} from 'react-router-dom';

import {UserContext} from '../../context/userContext';

export interface AuthRouteProps {
    children:JSX.Element
}

export const NoAuthRoute = ({children}:AuthRouteProps):JSX.Element=>{
	const {user,loading,error}:any=useContext(UserContext);
	console.log({user,loading,error});
	if (loading)
		return <p>Loading...</p>;
	if (error)
		return <Navigate to={'/'}/>;

	return !user? children :<Navigate to={'/reservations'} />;
};


