import React from 'react';
// @ts-ignore
import {Input} from '@moadigitalworks/boutique-app/dist/components/ui/Input';
import {InputProps} from '../../../interfaces';
import {color} from '../../../styles/colors';

export default function InputRender({...props}:InputProps):JSX.Element{
	return (
		<Input
			{...props}
			styles={{
				background:'0',
				height:'48px :laptop 45px :mobile 43px',
				color:'#fff',

				lineHeight:'1.5rem',
				fontColor: color.get('grey400'),

				labelFontSize:'0.75rem',
				labelFontColor: color.get('white'),
				labelFontWeight:'700'
			}}
		/>
	);
}
