import React, {createContext, useState} from 'react';
import {login, refreshJWT, setUpPassword, signup, userData} from '../actions/restActions';
import {LoginData, ResetData, SignupData, User} from '../interfaces';

export const UserContext:React.Context<{user:null}> =createContext({user:null});
interface userProviderProps {
	children:JSX.Element
}
const UserContextProvider=({children}:userProviderProps):JSX.Element=>{
	const [user,setUser]:[User|null,React.Dispatch<React.SetStateAction<User | null>>] =useState<User|null>(null);
	const [loading,setLoading]:[boolean|null,React.Dispatch<React.SetStateAction<boolean | null>>] =useState<boolean|null>(true);
	const [error,setError]:[Error|null,React.Dispatch<React.SetStateAction<Error | null>>] =useState<Error|null>(null);

	const requestUser=async():Promise<any> => setUser(await userData());
	const refresh=async():Promise<any> => {
		setLoading(true);
		refreshJWT()
		.then(():Promise<any>=>requestUser())
		.catch((e:Error):void=> {
			setError(e);
			setUser(null);
		})
		.finally(():void=>setLoading(false));
	};
	const value:any={
		user,
		loading,
		error,

		setUser:(value:User):void=>{
			setUser(value);
		},
		unSetUser:():void=>{
			setUser(null);
		},
		userLogin:async(data:LoginData):Promise<any> =>
			setUser(await login(data).catch()),
		userSignup:async(data:SignupData):Promise<any> => setUser(await signup(data)),

		setUpPassword:async(data:ResetData):Promise<any> =>
			setUser(await setUpPassword(data).catch()),
		refresh
	};
	return (
		<UserContext.Provider value={value} >
			{children}
		</UserContext.Provider>
	);
};
export default UserContextProvider;
