import React from 'react';
import {FormButton, Input} from '../../components/ui';
import {InputRadio} from '../../components/ui/InputRadio';
import {FormProps} from '../../interfaces';
// @ts-ignore
import {Message} from '@moadigitalworks/boutique-app/dist/components/ui';

interface ReservationDDetailRenderProps extends FormProps {
	reservation:any
}

export default function ReserveBillingRender({submitLoading,errors,onChangeInput,onSubmit}:ReservationDDetailRenderProps):JSX.Element{
	return (

		<form className='w-100 h-auto flex direction-column'
			onSubmit={onSubmit}
		>
			<p>SELECT PAYMENT METHOD</p>
			<InputRadio
				className='align-self-start'
				label='Visa Ending in ****4538'
			/>
			<hr className='w-100'/>
			<InputRadio
				className='align-self-start'
				label='Add new credit card'
			/>
			<Input
				name={'cardName'}
				label={'Name On Card'}
				placeholder={'Add same as credit card'}
				onChange={onChangeInput}
			/>
			<Input
				name={'cardNumber'}
				label={'Credit card Number'}
				placeholder={'Add same as credit card'}
				onChange={onChangeInput}
			/>
			<div className='grid2 col-gap'>
				<Input
					name={'ccv'}
					label={'CCV'}
					placeholder={'****'}
					onChange={onChangeInput}
				/>
				<Input
					name={'expirationDate'}
					label={'EXP. DATE'}
					placeholder={'MM/YY'}
					onChange={onChangeInput}
				/>
			</div>
			{!submitLoading && <FormButton className='mb-2'>
				Continue
			</FormButton>}
			{errors.cardName?.required && <Message message='Card Name is required' color='red'/>}
			{errors.cardNumber?.required && <Message message='Card Number is required' color='red'/>}
			{errors.ccv?.required && <Message message='CCV code is required' color='red'/>}
			{errors.expirationDate?.required && <Message message='Exp. Date is required' color='red'/>}
			{errors.submitError && <Message message={errors.submitError} color='red'/>}
		</form>

	);
}
