import React from 'react';
import MobileLayoutRender, {LayoutProps} from './MobileLayoutRender';

export const MobileLayout = ({children, ...props}:LayoutProps):JSX.Element=>{
	return <MobileLayoutRender
		{...props}
	>
		{children}
	</MobileLayoutRender>;
};
