import React from 'react';
// @ts-ignore
import {InputProps} from '../../../interfaces';

export default function InputRadioRender({className,label,...props}:InputProps):JSX.Element{
	return (
		<div className={className}>
			<input
				{...props}
				type='radio'
			/>
			<label className='ml'>{label}</label>
		</div>
	);
}
