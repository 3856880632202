import React from 'react';
import {AuthLayout} from '../../components';
import {FormButton} from '../../components/ui/FormButton';
import {FormProps} from '../../interfaces';
import {Input} from '../../components/ui';
// @ts-ignore
import {Message} from '@moadigitalworks/boutique-app/dist/components/ui';
import {Button} from '../../components/ui/Button';
import {color} from '../../styles/colors';

export default function LoginRender({onSubmit,errors,submitLoading,onChangeInput}:FormProps):JSX.Element{
	return (
		<AuthLayout>
			<>
				<h2>Login</h2>
				<form
					className='w-100 max-w-500px flex direction-column align-center pver-4'
					onSubmit={onSubmit}
				>
					<Input
						name='email'
						label = 'Email'
						placeholder='Email'
						onChange={onChangeInput}
					/>
					<Input
						name='password'
						label = 'Password'
						placeholder='Password'
						type='password'
						onChange={onChangeInput}
					/>

					{!submitLoading?
						<FormButton
							className='w-100'
							color={color.get('green-valay')}
						>
							Login
						</FormButton>:<div/>
					}
					{errors?<div className='mt'>
						{errors.email?.required && <Message message='Email is required' color='red'/>}
						{errors.email?.pattern && <Message message='Please add a valid email' color='red'/>}
						{errors.password && <Message message='Password is required' color='red'/>}
						{errors.submitError && <Message message={errors.submitError} color='red'/>}
					</div>: null}
				</form>
				<div className='flex direction-column align-center'>
					<p>Don’t have an account? </p>
					<Button
						label='Signup'
						type='link'
						to='/signup'
					/>
				</div>

			</>
		</AuthLayout>
	);
}