import React from 'react';


export function BackArrow():JSX.Element{

	return (
		<svg
			width={23}
			height={25}
			viewBox="0 0 23 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x={1.93115}
				y={11.0707}
				width={21.0688}
				height={3.20522}
				rx={1}
				fill="#fff"
			/>
			<rect
				width={17.5559}
				height={3.22386}
				rx={1}
				transform="scale(1.00559 .99438) rotate(-45 15.142 5.829)"
				fill="#fff"
			/>
			<rect
				width={17.5559}
				height={3.22334}
				rx={1}
				transform="scale(1.00559 .99438) rotate(45 -11.472 7.975)"
				fill="#fff"
			/>
		</svg>
	);
}
