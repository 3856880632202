import axios, {AxiosError, AxiosInstance, AxiosResponse} from 'axios';
import {LoginData, ResetData, SignupData} from '../interfaces';
import {addHeaders} from '../tools';

const valayApi:AxiosInstance = axios.create({
	baseURL:process.env.REACT_APP_API,
	headers:addHeaders({'Content-Type':'application/json'})
});
valayApi.defaults.withCredentials=true;

export const signup = (data:SignupData):Promise<any|AxiosResponse> =>{
	return valayApi.post('/authentication/signup-company-admin',data)
	.then((res:AxiosResponse):void=>res.data)
	.catch((e:AxiosError):Error=> {
		let error:any= e.response?.data;
		throw error?.message || 'Signup Error';
	});
};

export const login = (data:LoginData):Promise<any|AxiosResponse> =>{
	return valayApi.post('/authentication/login',data)
	.then((res:AxiosResponse):AxiosResponse=> {
		return res.data;
	})
	.catch((e:AxiosError):Error=> {
		let error:any= e.response?.data;
		throw error?.message || 'Signup Error';
	});
};


export const refreshJWT = ():Promise<any|AxiosResponse> => {
	return valayApi.post(`authentication/refresh-jwt`)
	.catch((e:AxiosError):void=> console.log(e));
};

export const userData = ():Promise<any|AxiosResponse> => {
	return valayApi.get(`user/user-jwt`)
	.then((res:AxiosResponse):any=> res.data)
	.catch(():void=> {
		refreshJWT()
		.then((res:AxiosResponse):any=> res.data)
		.catch((e:AxiosError):void=>console.log(e));
	});
};


export const logout = ():Promise<any|AxiosResponse> =>{
	return valayApi.post('/authentication/logout')
	.then((res:AxiosResponse):AxiosResponse=>res)
	.catch((e:AxiosError):void=> {
		throw e;
	});

};

export const getEnvSettings = ():Promise<any|AxiosResponse> =>{
	return valayApi.get('/environment-settings')
	.then((res:AxiosResponse):AxiosResponse=>res)
	.catch((e:AxiosError):void=> {
		throw e;
	});
};

export const profilePicture = (file:any):Promise<any|AxiosResponse> =>{
	const formData:FormData=new FormData();
	formData.append('file',file);

	return valayApi.put('/user/picture', formData, {
		headers: {
			'Content-Type': file.type
		}
	})
	.then((res:AxiosResponse):AxiosResponse=>res)
	.catch((e:AxiosError):void=> {
		throw e;
	});
};

export const companyPicture = (file:any,id:string):Promise<any|AxiosResponse> =>{
	const formData:FormData=new FormData();
	formData.append('file',file);
	formData.append('id',id);
	return valayApi.put('/company', formData, {
		headers: {
			'Content-Type': file.type
		}
	})
	.then((res:AxiosResponse):AxiosResponse=>res)
	.catch((e:AxiosError):void=> {
		throw e;
	});
};


export const getResetPasswordByToken = (token:string):Promise<any|AxiosResponse> =>{
	return valayApi.get('/authentication/email-from-password-reset-token',{
		params:{token}
	})
	.then((res:AxiosResponse):AxiosResponse=>res)
	.catch((e:AxiosError):void=> {
		throw e;
	});
};

export const setUpPassword = (data:ResetData):Promise<any|AxiosResponse> =>{
	return valayApi.post('/authentication/reset-password-login',data)
	.then((res:AxiosResponse):AxiosResponse=> {
		return res.data;
	})
	.catch((e:AxiosError):Error=> {
		let error:any= e.response?.data;
		throw error?.message || 'Signup Error';
	});
};


