import React, {FormEvent} from 'react';
import {FormButton, Rating} from '../../components/ui';
import {FormProps} from '../../interfaces';
import {color} from '../../styles/colors';

interface ReservationDDetailRenderProps {
	reservation:any
	onSubmit: FormProps['onSubmit']
	showRatePopup : (e:FormEvent)=>void
}

export default function ReservationDetailRender({reservation,onSubmit,showRatePopup}:ReservationDDetailRenderProps):JSX.Element{
	const {place,status}:any=reservation;
	console.log({ratingType: typeof place.rating});
	return (
		<form
			onSubmit={onSubmit}
			className='w-100 h-auto flex direction-column align-center'
		>
			<div className=' w-100 max-h-300px black h-500px max-h-30vh  '>
				{place.cover &&
					<picture className='w-100 h-50 black'>
						<source
							media='(max-width:640px)'
							srcSet={`${place.cover?.small}`}
						/>
						<source
							media='(max-width:1600px)'
							srcSet={`${place.cover?.small}`}
						/>
						<img
							className='w-100 h-100 black obj-cover'
							src={place.cover ? `${place.cover?.small}` : ''}
							alt={'place cover image'}
						/>
					</picture>
				}
			</div>
			<p className='pt f-700 f-large t-green-valay'>{reservation.pickedUpAt} - {reservation.deliveryTime}</p>
			<p className='pt f-300 f-medium'>{reservation.createdAt}</p>
			{status.label !== 'Pending' &&
				<div>
					<p className='pt f-300 f-small t-grey400'>PAYMENT METHOD</p>
					<p className='t-grey900'>Visa Ending in ****4538</p>
				</div>
			}
			<div>
				<p className='pt f-300 f-small t-grey400'>TOTAL</p>
				<p className='pb f-700'>
					${reservation.order.total+' '}
					{reservation.status.label==='Pending' ?'(Payment Pending)':'(Payment Completed)'}
				</p>

			</div>
			{status.label === 'PickUp' &&
				<div>
					<p className='pt f-300 f-small t-grey400'>PICKUP REQUESTED</p>
					<p className='t-grey900'>2:30 Mins ago.</p>
				</div>
			}
			{status.label === 'Closed' &&
			<>
				<div>
					<p className='pt f-300 f-small t-grey400'>CAR DELIVERED</p>
					<p className='t-grey900'>3:50 pm</p>
				</div>
				{(typeof place.rating ==='number') ?

					<Rating rating={place.rating} size={32} maxRating={5}/>

					:
					<FormButton
						color={color.get('green-valay')}
						className='mb-2'
						onClick={showRatePopup}
					>
						RATE THIS PLACE
					</FormButton>

				}
			</>
			}
			{status.label === 'Pending' &&
				<FormButton className='mb-2'>
					REQUEST CAR PICKUP & PAY
				</FormButton>
			}
		</form>

	);
}
