import gql from 'graphql-tag';

const queries:any={
	'getUserJwt': gql`
	query getUserJwt{
	  getUserJwt {
		company {
		  createdAt
		  id
		  name
		  stripeId
		  updatedAt
		}
		createdAt
		email
		emailVerified
		firstName
		id
		lastName
		password
		profilePicture {
		  small
		}
		role {
		  createdAt
		  id
		  label
		  updatedAt
		}
		stripeId
		updatedAt
	  }
	}`,
	'getCompanies': gql`
	query getCompanies($limit: Float!, $skip: Float!) {
	  getCompanies(limit: $limit, skip: $skip) {
		createdAt
		id
		name
		stripeId
		updatedAt
	  }
	}`,
	'getPlacesByCompany': gql`
		query getPlacesByCompany(
		$limit: Float!,
		$skip: Float!,
		$sort: String!,
		$valet: Boolean
	  ) {
	  getPlacesByCompany(
		limit: $limit
		skip: $skip
		sort: $sort
		valet: $valet
	  ) {
		addressComponents {
		  longName
		  shortName
		  types
		}
		administrativeAreaLevel1
		administrativeAreaLevel2
		auxDistanceMeters
		available
		city
		company {
		  createdAt
		  id
		  name
		  stripeId
		  updatedAt
		}
		country
		cover {
		  large
		  small
		}
		createdAt
		description
		email
		formattedAddress
		hourlyRate
		id
		location {
		  coordinates
		  type
		}
		name
		numberOfReviews
	
		phone
		placeId
		rating
		timeZone
		types
		updatedAt
		valet
	  
		website
	  }
	}`,
	'getReservations': gql`
	
	query getReservations($limit:Float!,$skip:Float!){
	  getReservations(limit: $limit, skip: $skip) {
		car {
		  brand
		  createdAt
		  id
		  model
		  plates
		  updatedAt
		  year
		}
		createdAt
		dateEnd
		dateStart
		deliveryTime
		id
		    place {
		  available
		  city
		  country
		  cover{
			large
			small
		  }
		  createdAt
		  description
		  email
		  formattedAddress
		  hourlyRate
		  id
		  name
		  numberOfReviews
		  phone
		  placeId
		  rating
		  timeZone
		  types
		  updatedAt
		  valet
		  website
		}
		order {
		  createdAt
		  discount
		  email
		  id
		  name
		  paid
	  
		  subtotal
		  tip
		  total
	  
		  transactionId
		  transferId
		  updatedAt
		}
		pickUpCode
		pickedUpAt
	   
		status {
		  createdAt
		  id
		  label
		  updatedAt
		}
		type {
		  createdAt
		  id
		  label
		  updatedAt
		}
		updatedAt
	  }
	}`
};


export const query=(value:string):any=>{
	return queries[value];
};
