import React, {useContext, useEffect} from 'react';
import {Route, Routes} from 'react-router-dom';
import {EmailVerified, Login, ReservationDetail, Reservations, SetUpPW, SetUpPWValet} from './pages';
import {UserContext} from './context/userContext';
import {EnvContext} from './context/envContext';
import {ReserveBilling} from './pages/ReserveBilling';
import {AuthRoute, NoAuthRoute, ReservationContainer} from './containers';

function App():JSX.Element{
	const {getEnv}:any=useContext(EnvContext);
	const {refresh,user}:any=useContext(UserContext);
	useEffect(():void=>{
		if(!user){
			refresh();
			getEnv();
		}
	},[user]);
	return (
		<Routes>
			<Route path='/' element={<NoAuthRoute><Login/></NoAuthRoute>}/>
			<Route path='/email-verified' element={<EmailVerified/>}/>
			<Route path='/setup-pw/:token' element={<SetUpPW/>}/>
			<Route path='/setup-pw-valet/:token' element={<SetUpPWValet/>}/>
			<Route path='/reservations' element={<AuthRoute><Reservations/></AuthRoute>}/>
			<Route path='/reservations' element={<AuthRoute><ReservationContainer/></AuthRoute>}>
				<Route path=':id' element={<ReservationDetail/>}/>
				<Route path=':id/billing' element={<ReserveBilling/>}/>
			</Route>

			{/* DashBoard */}

		</Routes>
	);
}

export default App;
