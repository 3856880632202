import React from 'react';
// @ts-ignore
import {CardBase} from '@moadigitalworks/boutique-app/dist/components';
import {PlaceIcon} from 'components/PlaceIcon';
import {Link} from 'react-router-dom';

interface ReservationCardRender {
	name:string
	cover:{small:string,large:string}
	date:string
	valet:boolean
	time:string
}
export default function ReservationCardRender({name,cover,valet,date,time}:ReservationCardRender):JSX.Element{
	return (
		<CardBase
			className=' w-400px mobile: '
			$width='100%'
			padding='12px 12px 16px 12px'
		>
			<Link
				className='flex h-100 direction-column t-black  mobile:grid2 col-gap '
				// to={`/reservations/${reservation.id}`}
				to={`/reservations`}
			>
				<div className='w-100 black' style={{height:'150px'}} >
					{cover &&
					<picture className='w-100 h-50 black'>
						<source
							media='(max-width:640px)'
							srcSet={`${cover?.small}`}
						/>
						<source
							media='(max-width:1600px)'
							srcSet={`${cover?.small}`}
						/>
						<img
							className='w-100 h-100 black obj-cover'
							src={cover ? `${cover?.small}` : ''}
							alt={'place cover image'}
						/>
					</picture>
					}
				</div>

				<div className='grows flex direction-column justify-between'>
					<div className='flex justify-between mt'>
						<h6 className='mr'>{name}</h6>
						<PlaceIcon valet={valet}/>
					</div>
					<div className='f-700'>
						<p className=''>{date}</p>
						<p className='t-green-valay f-medium'>{time}</p>
					</div>
				</div>

			</Link>
		</CardBase>
	);
}
