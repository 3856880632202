import React from 'react';

interface CloseIconProps {
	className?:string
    action? : ()=>void
}

export function CloseIcon({className,action}:CloseIconProps):JSX.Element{

	return (
		<svg
			className={className}
			onClick={action}
			width={22}
			height={22}
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="2.82861"
				width={26}
				height={4}
				rx={1}
				transform="rotate(45 2.82861 0)"
				fill="#171F42"
			/>
			<rect
				y="18.3848"
				width={26}
				height={4}
				rx={1}
				transform="rotate(-45 0 18.3848)"
				fill="#171F42"
			/>
		</svg>
	);
}
