import React from 'react';
import {Reservation} from 'interfaces';
import {MobileLayout} from 'components/Layouts';
import {ReservationCard} from './components/ReservationCard';

interface ReservationsRenderProps {
	reservations:Array<Reservation>
}
export default function ReservationsRender({reservations}:ReservationsRenderProps):JSX.Element{
	return (
		<MobileLayout>
			<div className='grid2 col-gap mobile:grid1 '>
				{reservations.map((reservation:Reservation):JSX.Element=>
					<ReservationCard key={reservation.id} reservation={reservation}/>
				)}
			</div>
		</MobileLayout>
	);
}
