import React from 'react';
// @ts-ignore
import {Message} from '@moadigitalworks/boutique-app/dist/components/ui';
import {Popup} from 'portals/Popup';
import {Rating} from 'components/ui';
import {FormProps} from 'interfaces';
import {CloseIcon} from 'components/icons';


interface PopupRenderProps extends FormProps{
	formRef:any
	hidePopup:()=>void
}


export default function RatePlaceRender({formRef,errors,hidePopup,onSubmit}:PopupRenderProps):JSX.Element {
	return (
		<Popup >
			<div
				id={'wtf'}
				className='grows w-100 flex direction-column align-center justify-center pver-2 phor'
				style={{backgroundColor:'rgba(0,0,0,0.8)'}}
			>
				<div
					ref={formRef}
					style={{alignSelf:'center'}}
					className=' white t-black br max-w-300px'
				>
					<div className='flex justify-end mver mhor'>
						<CloseIcon action={hidePopup}/>
					</div>
					<form
						className='w-100 flex direction-column align-center pt-3 pr pb-2 pl'
						onSubmit={onSubmit}
					>

						<h4 className='f-large mb'>Rate This Place</h4>
						<Rating rating={4} maxRating={5} size={32}/>


					</form>
				</div>
			</div>
		</Popup>
	);
}
