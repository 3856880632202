import React, {SetStateAction, useState} from 'react';
import ReservationContainerRender from './ReservationContainerRender';
import {Outlet, Params, useParams} from 'react-router-dom';

import {fakeReservationsArray} from '../../tools/mucks';
import {Reservation} from '../../interfaces';

export interface ReservationContext {
	reservation:Reservation,
	setConfirmPopup:SetStateAction<boolean>
	setRatePopup:SetStateAction<boolean>
}

export const ReservationContainer = ():JSX.Element=>{
	const [confirmPopup,setConfirmPopup]:Array<any> =useState(false);
	const [ratePopup,setRatePopup]:Array<any> =useState(false);
	const {id:reservationId}:Params=useParams();

	let reserId: number =parseInt(reservationId||'0');
	let reservation:Reservation = fakeReservationsArray[reserId<fakeReservationsArray.length?reserId:0];

	let reservationContext:ReservationContext={reservation, setConfirmPopup, setRatePopup};

	return <ReservationContainerRender
		reservation={reservation}
		confirmPopup={confirmPopup}
		ratePopup={ratePopup}

		setConfirmPopup={setConfirmPopup}
		setRatePopup={setRatePopup}
	>
		<Outlet context={reservationContext}/>
	</ReservationContainerRender>;
};


