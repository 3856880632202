import React, {SetStateAction} from 'react';
import {MobileLayout} from 'components/Layouts';
import {PlaceIcon} from 'components';
import {ConfirmPickUpPopup, RatePlacePopup} from 'popups';


interface ReservationDDetailRenderProps  {
	reservation:any
	children:JSX.Element
	confirmPopup:boolean
	ratePopup:boolean

	setConfirmPopup:(visible:boolean)=>SetStateAction<boolean>
	setRatePopup:(visible:boolean)=>SetStateAction<boolean>
}

export default function ReservationContainerRender({reservation,confirmPopup,ratePopup,children,setConfirmPopup,setRatePopup}:ReservationDDetailRenderProps):JSX.Element{
	const {place,status}:any=reservation;
	return (
		<MobileLayout deliveryCode={status.label==='PickUp'? 2322:undefined}>
			{confirmPopup&&<ConfirmPickUpPopup setHidden={():any=>setConfirmPopup(false)}/>}
			{ratePopup&&<RatePlacePopup setHidden={():any=>setRatePopup(false)}/>}
			<div className='w-100 flex direction-column align-center center'>
				<PlaceIcon valet={place.valet}/>
				<h4 className='mt'>
					{place.name}
				</h4>
				{children}
			</div>

		</MobileLayout>
	);
}
