import React, {useState} from 'react';
import ReserveBillingRender from './ReserveBillingRender';
import {NavigateFunction, Params, useNavigate, useOutletContext, useParams} from 'react-router-dom';
import {FormField, FormHook, useForm} from '../../hooks/useForm';

export const ReserveBilling = ():JSX.Element=>{
	const {id:reservationId}:Params=useParams();
	const navigate:NavigateFunction=useNavigate();
	const {reservation,setConfirmPopup}:any =useOutletContext();
	const [submitError,setSubmitError]:any = useState('');
	const initForm:Array<FormField>  = [
		{
			name:'cardName',
			default: '',
			required: true
			// pattern: /^\S+@\S+\.\S+$/
		},
		{
			name:'cardNumber',
			default: '',
			required: true
		},
		{
			name:'ccv',
			default: '',
			required: true
		},
		{
			name:'expirationDate',
			default: '',
			required: true
		}
	];


	const onSubmit = (data:any):void=>{
		console.log('submit',data);
		setConfirmPopup(true);
		//navigate(`/reservations/${reservationId}`);
	};
	const {
		errors,
		handleSubmitForm,
		onChangeInput,
		loading
	}:FormHook=useForm(initForm, onSubmit);

	console.log({reservationId,setConfirmPopup});
	return <ReserveBillingRender
		reservation={reservation}
		submitLoading={loading}
		errors={{...errors,submitError}}
		onChangeInput={onChangeInput}
		onSubmit={handleSubmitForm}
	/>;
};
