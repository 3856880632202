import React, {FormEvent} from 'react';
import ReservationDetailRender from './ReservationDetailRender';
import {NavigateFunction, useNavigate, useOutletContext} from 'react-router-dom';
import {ReservationContext} from '../../containers/ReservationContainer/ReservationContainer';

export const ReservationDetail = ():JSX.Element=>{
	const navigate:NavigateFunction=useNavigate();
	const {reservation, setRatePopup}:ReservationContext =useOutletContext();
	const onSubmit=(e:FormEvent):void=>{
		e.preventDefault();
		console.log('submit');
		navigate('/reservations/l/billing');
	};

	const showRatePopup = (e:FormEvent):void=>{
		e.preventDefault();
		// @ts-ignore
		setRatePopup(true);

	};


	console.log({reservation});
	return <ReservationDetailRender
		reservation={reservation}
		showRatePopup={showRatePopup}
		onSubmit={onSubmit}
	/>;
};
