import React from 'react';
import ReservationCardRender from './ReservationCardRender';
import {Reservation} from 'interfaces';

interface PlaceCardProps {
	reservation:Reservation
}

export const ReservationCard = ({reservation}:PlaceCardProps):JSX.Element=>{

	const dateTuple = (dateString:string):Array<string> =>
	{
		const month:Array<string> =[
			'Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'
		];
		let date:Date= new Date(dateString);
		let day:string= `${month[date.getMonth()]} ${date.getMonth()}, ${date.getFullYear()}`;
		let time:string= date.getHours() > 12? `${date.getHours()-12} : ${date.getMinutes()} pm` : `${date.getHours()} : ${date.getMinutes()} am`;
		return [day,time];
	};

	dateTuple(reservation.dateStart);
	return <ReservationCardRender
		name={reservation.place.name}
		cover={reservation.place.cover}
		valet={reservation.place.valet}
		date={dateTuple(reservation.dateStart)[0]}
		time={dateTuple(reservation.dateStart)[1]}
	/>;
};
