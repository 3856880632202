import React, {Ref, useRef, useState} from 'react';
import RatePlaceRender from './RatePlaceRender';
import useOnClickOut from 'hooks/useOnClickOut';

interface CategoryPopupProps{
	setHidden:()=>void
}
export const RatePlace = ({setHidden}:CategoryPopupProps):JSX.Element=>{
	const formRef:Ref<Element> = useRef(null);
	const [submitError]:any = useState('');
	const [submitLoading]:any = useState('');
	useOnClickOut(formRef,setHidden);


	const payOrder= (data:any):void=>{
		console.log('Pay Order',{data});
	};


	return <RatePlaceRender
		formRef = {formRef}
		submitLoading={submitLoading}
		errors={{submitError}}

		hidePopup={setHidden}
		onSubmit={payOrder}
	/>;
};

