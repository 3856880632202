import {Car, GraphItem, Order, Place, Reservation, User} from '../interfaces';

export const fakeUsersArray:Array<User> =[
	{
		firstName:'Daniel',
		lastName:'Serrano'
	}
];

export const fakeReservationStatus =(label:string='Pending'):GraphItem => {
	const reservationByStatusLabel: any = {
		Pending: {
			createdAt: '2022-06-29T20:56:27.750Z',
			id: '600c88bb-e02d-450a-a9cf-a7a4be1c5dbb',
			label: 'Pending',
			updatedAt: '2022-06-29T20:56:27.750Z'
		},
		Parked: {
			createdAt: '2022-06-29T20:56:27.750Z',
			id: '3918ebc2-68f5-405a-9a6e-d2ffce843511',
			label: 'Parked',
			updatedAt: '2022-06-29T20:56:27.750Z'
		},
		PickUp: {
			createdAt: '2022-06-29T20:56:27.750Z',
			id: '9cb51c0c-c908-4fc2-8c08-fc977468547a',
			label: 'PickUp',
			updatedAt: '2022-06-29T20:56:27.750Z'
		},
		Closed: {
			createdAt: '2022-06-29T20:56:27.750Z',
			id: 'b5168290-e0ed-11ec-9d64-0242ac120002',
			label: 'Closed',
			updatedAt: '2022-06-29T20:56:27.750Z'
		}
	};
	return reservationByStatusLabel[label];
};

export const fakeReservationType =(label:string='Pending'):GraphItem => {
	const reservationTypeByLabel: any = {
		Pending: {
			createdAt: '2022-06-29T20:56:27.750Z',
			id: '600c88bb-e02d-450a-a9cf-a7a4be1c5dbb',
			label: 'Pending',
			updatedAt: '2022-06-29T20:56:27.750Z'
		},
		Parked: {
			createdAt: '2022-06-29T20:56:27.750Z',
			id: '3918ebc2-68f5-405a-9a6e-d2ffce843511',
			label: 'Parked',
			updatedAt: '2022-06-29T20:56:27.750Z'
		},
		PickUp: {
			createdAt: '2022-06-29T20:56:27.750Z',
			id: '9cb51c0c-c908-4fc2-8c08-fc977468547a',
			label: 'PickUp',
			updatedAt: '2022-06-29T20:56:27.750Z'
		},
		Closed: {
			createdAt: '2022-06-29T20:56:27.750Z',
			id: 'b5168290-e0ed-11ec-9d64-0242ac120002',
			label: 'Closed',
			updatedAt: '2022-06-29T20:56:27.750Z'
		}
	};
	return reservationTypeByLabel[label];
};

export const fakePlacesArray:Array<Place> =[
	{addressComponents: null,
		administrativeAreaLevel1: 'California',
		administrativeAreaLevel2: 'Orange County',
		auxDistanceMeters: null,
		available: false,
		city: 'Anaheim',
		company: {
			createdAt: '2022-06-29T21:26:55.850Z',
			id: '336beca0-f7f2-11ec-bf2b-85a34739faae',
			name: 'adidas company',
			stripeId: null,
			updatedAt: '2022-06-29T21:26:55.850Z'
		},
		country: 'United States',
		cover:{
			large: 'https://storage.googleapis.com/valay-176920/places…f77c0-f7f3-11ec-bf2b-85a34739faae/covers/large-11',
			small: 'https://storage.googleapis.com/valay-176920/places…f77c0-f7f3-11ec-bf2b-85a34739faae/covers/small-11'
		},
		createdAt: '2022-06-29T21:35:21.918Z',
		description: null,
		email: null,
		formattedAddress: 'Anaheim, CA 92802, USA',
		hourlyRate: 8,
		id: '610f77c0-f7f3-11ec-bf2b-85a34739faae',
		location: { coordinates: [0,0], type: 'Point'},
		name: 'Pending Reservation',
		numberOfReviews: null,
		phone: null,
		placeId: 'ChIJyftGqMLX3IARKrJ3RKgXQBw',
		rating: null,
		timeZone: 'America/Los_Angeles',
		types: ['premise', 'parking', 'point_of_interest', 'establishment'],
		updatedAt: '2022-06-29T21:35:21.918Z',
		valet: true,
		website: null
	},
	{
		addressComponents: [],
		administrativeAreaLevel1: 'Sicilia',
		administrativeAreaLevel2: 'Provincia di Palermo',
		auxDistanceMeters: null,
		available: false,
		city: 'Palermo',
		company: {
			createdAt: '2022-06-29T21:26:55.850Z',
			id: '336beca0-f7f2-11ec-bf2b-85a34739faae',
			name: 'adidas company',
			stripeId: null,
			updatedAt: '2022-06-29T21:26:55.850Z'},
		country: 'Italy',
		cover: {
			large: 'https://storage.googleapis.com/valay-176920/places…020b0-f8bf-11ec-91e5-1fe817e5f0bb/covers/large-37',
			small: 'https://storage.googleapis.com/valay-176920/places…020b0-f8bf-11ec-91e5-1fe817e5f0bb/covers/small-37'
		},
		createdAt: '2022-06-30T21:56:07.356Z',
		description: null,
		email: null,
		formattedAddress: 'Piazza Vittorio Emanuele Orlando, 49, 90138 Palermo PA, Italy',
		hourlyRate: 8,
		id: '71d020b0-f8bf-11ec-91e5-1fe817e5f0bb',
		location: {coordinates: [0,0], type: 'Point'},
		name: 'Closed Reservation',
		numberOfReviews: null,
		phone: '+39 091 333139',
		placeId: 'ChIJEXB97F3vGRMRDFp5cUa7cu4',
		rating: null,
		timeZone: 'Europe/Rome',
		types: ['parking', 'point_of_interest', 'establishment'],
		updatedAt: '2022-06-30T21:56:07.356Z',
		valet: false,
		website: 'https://www.apcoa.it/parcheggi-in/palermo/orlando/'
	},
	{
		addressComponents: [],
		administrativeAreaLevel1: 'Fake Place',
		administrativeAreaLevel2: 'Provincia di Palermo',
		auxDistanceMeters: null,
		available: false,
		city: 'Palermo',
		company: {
			createdAt: '2022-06-29T21:26:55.850Z',
			id: '336beca0-f7f2-11ec-bf2b-85a34739faae',
			name: 'adidas company',
			stripeId: null,
			updatedAt: '2022-06-29T21:26:55.850Z'},
		country: 'Italy',
		cover: {
			large: 'https://storage.googleapis.com/valay-176920/places…020b0-f8bf-11ec-91e5-1fe817e5f0bb/covers/large-37',
			small: 'https://storage.googleapis.com/valay-176920/places…020b0-f8bf-11ec-91e5-1fe817e5f0bb/covers/small-37'
		},
		createdAt: '2022-06-30T21:56:07.356Z',
		description: null,
		email: null,
		formattedAddress: 'Piazza Vittorio Emanuele Orlando, 49, 90138 Palermo PA, Italy',
		hourlyRate: 8,
		id: '71d020b0-f8bf-11ec-91e5-1fe817e5f0bb',
		location: {coordinates: [0,0], type: 'Point'},
		name: 'Valet Reservation',
		numberOfReviews: null,
		phone: '+39 091 333139',
		placeId: 'ChIJEXB97F3vGRMRDFp5cUa7cu4',
		rating: null,
		timeZone: 'Europe/Rome',
		types: ['parking', 'point_of_interest', 'establishment'],
		updatedAt: '2022-06-30T21:56:07.356Z',
		valet: true,
		website: 'https://www.apcoa.it/parcheggi-in/palermo/orlando/'
	},
	{
		addressComponents: [],
		administrativeAreaLevel1: 'F',
		administrativeAreaLevel2: 'Provincia di Palermo',
		auxDistanceMeters: null,
		available: false,
		city: 'Palermo',
		company: {
			createdAt: '2022-06-29T21:26:55.850Z',
			id: '336beca0-f7f2-11ec-bf2b-85a34739faae',
			name: 'adidas company',
			stripeId: null,
			updatedAt: '2022-06-29T21:26:55.850Z'},
		country: 'Italy',
		cover: {
			large: 'https://storage.googleapis.com/valay-176920/places…020b0-f8bf-11ec-91e5-1fe817e5f0bb/covers/large-37',
			small: 'https://storage.googleapis.com/valay-176920/places…020b0-f8bf-11ec-91e5-1fe817e5f0bb/covers/small-37'
		},
		createdAt: '2022-06-30T21:56:07.356Z',
		description: null,
		email: null,
		formattedAddress: 'Piazza Vittorio Emanuele Orlando, 49, 90138 Palermo PA, Italy',
		hourlyRate: 8,
		id: '71d020b0-f8bf-11ec-91e5-1fe817e5f0bb',
		location: {coordinates: [0,0], type: 'Point'},
		name: 'Rated Reservation',
		numberOfReviews: null,
		phone: '+39 091 333139',
		placeId: 'ChIJEXB97F3vGRMRDFp5cUa7cu4',
		rating: 3.8,
		timeZone: 'Europe/Rome',
		types: ['parking', 'point_of_interest', 'establishment'],
		updatedAt: '2022-06-30T21:56:07.356Z',
		valet: true,
		website: 'https://www.apcoa.it/parcheggi-in/palermo/orlando/'
	}
];

export const fakeCar:Car = {
	brand :'Chevrolet',
	createdAt :'01/05/2018',
	id :'5743829',
	updatedAt:'01/05/2018',
	user: fakeUsersArray[0],
	year: 2012
};

export const fakeOrdersArray:Array<Order> =[
	{
		createdAt:'01/05/2018',
		discount: 0,
		email:'order@moadw.com',
		id:'4s56d4as5d',
		name:'OrderName0',
		paid:false,
		paymentType:{
			createdAt :'01/05/2018',
			id:'fdsjnfkds',
			label:'Card'
		},
		subtotal:45,
		tip:0,
		total:45,
		transactionId:'dasnfaswdas54d56as',
		transferId:'njashdisahdisad1sa6',
		updatedAt:'01/05/2018'
	},
	{
		createdAt:'01/05/2018',
		discount: 0,
		email:'order@moadw.com',
		id:'4s56d4as5d',
		name:'OrderName0',
		paid:true,
		paymentType:{
			createdAt :'01/05/2018',
			id:'fdsjnfkds',
			label:'Card'
		},
		subtotal:45,
		tip:0,
		total:45,
		transactionId:'dasnfaswdas54d56as',
		transferId:'njashdisahdisad1sa6',
		updatedAt:'01/05/2018'
	}
];

export const fakeReservationsArray:Array<Reservation>=[
	{
		car: fakeCar,
		createdAt :'01/05/2018',
		dateEnd :'01/05/2018',
		dateStart :'01/05/2018',
		deliveryTime: '4:35 PM',
		id :'0',
		order :fakeOrdersArray[0],
		pickUpCode :'01/05/2018',
		pickedUpAt :'10:10 AM',
		place :fakePlacesArray[0],
		status :fakeReservationStatus('Pending'),
		type :{
			createdAt:'01/05/2018',
			id:'yutre',
			label:'type',
			updatedAt:'01/05/2018'
		},
		updatedAt:'01/05/2018',
		valet: fakeUsersArray[0]
	},
	{
		car: fakeCar,
		createdAt :'01/05/2018',
		dateEnd :'01/05/2018',
		dateStart :'01/05/2018',
		deliveryTime: '3:35 PM',
		id :'1',
		order :fakeOrdersArray[0],
		pickUpCode :'01/05/2018',
		pickedUpAt :'10:10 AM',
		place :fakePlacesArray[2],
		status :fakeReservationStatus('PickUp'),
		type :{
			createdAt:'01/05/2018',
			id:'yutre',
			label:'type',
			updatedAt:'01/05/2018'
		},
		updatedAt:'01/05/2018',
		valet: fakeUsersArray[0]
	},
	{
		car: fakeCar,
		createdAt :'01/05/2018',
		dateEnd :'01/05/2018',
		dateStart :'01/05/2018',
		deliveryTime: '1:10 PM',
		id :'2',
		order :fakeOrdersArray[0],
		pickUpCode :'01/05/2018',
		pickedUpAt :'10:10 AM',
		place :fakePlacesArray[1],
		status :fakeReservationStatus('Closed'),
		type :{
			createdAt:'01/05/2018',
			id:'yutre',
			label:'type',
			updatedAt:'01/05/2018'
		},
		updatedAt:'01/05/2018',
		valet: fakeUsersArray[0]
	},
	{
		car: fakeCar,
		createdAt :'01/05/2018',
		dateEnd :'01/05/2018',
		dateStart :'01/05/2018',
		deliveryTime: '1:10 PM',
		id :'3',
		order :fakeOrdersArray[0],
		pickUpCode :'01/05/2018',
		pickedUpAt :'10:10 AM',
		place :fakePlacesArray[3],
		status :fakeReservationStatus('Closed'),
		type :{
			createdAt:'01/05/2018',
			id:'yutre',
			label:'type',
			updatedAt:'01/05/2018'
		},
		updatedAt:'01/05/2018',
		valet: fakeUsersArray[0]
	}
];




