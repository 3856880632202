import React from 'react';
// @ts-ignore
import {Button} from '@moadigitalworks/boutique-app/dist/components/ui';
import {Option} from '../../../interfaces';


export interface MultiButtonRenderProps {
	className?:string

	activeColor?:string
	options : Array<Option>
	onClick? : ((value:number)=>void)|((value:string)=>void )| ((value:boolean)=>void)
}
interface RemderProps extends MultiButtonRenderProps {
	value:number|string|boolean
}

export default function MultipleButtonRender(
	{
		activeColor,value,className,options,onClick
	}:RemderProps):JSX.Element{
	return (
		<div className={className}>
			{options.map((option:Option):JSX.Element=>
				<Button
					key={option.label}
					onClick={():void=>console.log('Clik tip')}
					buttonType = 'button'
					styles={{
						width:'100% :laptop 100% :mobile 100%',
						height: '48px :laptop 48px :mobile 32px',
						fontWeight:'700',
						fontSize: '16px :mobile 12px',

						fontFamily: '"Sora", sanse-serif',
						color: option.value=== value? activeColor||'grey' : 'grey300'
					}}
				>
					{option.label}
				</Button>
			)}

		</div>

	);
}
