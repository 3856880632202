import React from 'react';
import ReservationsRender from './ReservationsRender';
import {ApolloError, useQuery} from '@apollo/client';
import {query} from '../../actions/queries';

export const Reservations = ():JSX.Element=>{
	const {data,loading,error}:any=useQuery(query('getReservations'),
		{
			variables:{limit:100,skip:0 },
			onError:(err:ApolloError):void=> console.log({err})
		}
	);

	if(loading )
		return <p>Loading...</p>;
	if(error)
		return <p>Error...</p>;
	return <ReservationsRender
		reservations={data?.getReservations}
	/>;
};
