import React, {createContext, useState} from 'react';

export const PlacesContext:React.Context<{user:null}> =createContext({user:null});
interface EnvProviderProps {
    children:JSX.Element
}
const PlacesContextProvider=({children}:EnvProviderProps):JSX.Element=>{
	const [isValet,setIsValet]:[boolean|null,React.Dispatch<React.SetStateAction<boolean | null>>] =useState<boolean|null>(true);
	const value:any={
		isValet,
		setIsValet
	};
	return (
		<PlacesContext.Provider value={value} >
			{children}
		</PlacesContext.Provider>
	);
};
export default PlacesContextProvider;
