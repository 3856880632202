import React from 'react';

export default function AppleStoreButtonRender():JSX.Element{
	return (
		<div >
			<svg
				width={150}
				height={50}
				viewBox="0 0 150 50"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_456_1599)">
					<path
						d="M137.668 -8.16406e-05H11.9184C11.46 -8.16406e-05 11.0071 -8.16406e-05 10.5499 0.00241836C10.1672 0.00491836 9.78761 0.0121809 9.40125 0.0182934C8.56189 0.0281706 7.72451 0.102014 6.89637 0.239181C6.0694 0.379334 5.26834 0.643563 4.52027 1.02293C3.77312 1.40551 3.09043 1.90263 2.49696 2.49626C1.90038 3.08821 1.40308 3.77244 1.02419 4.52262C0.64425 5.2713 0.380801 6.07359 0.242938 6.90177C0.103764 7.7289 0.028873 8.56557 0.0189375 9.40427C0.0073375 9.78752 0.0061125 10.172 0 10.5553V39.4482C0.0061125 39.8363 0.0073375 40.2123 0.0189375 40.6005C0.028876 41.4392 0.103767 42.2758 0.242938 43.1029C0.38042 43.9316 0.643885 44.7343 1.02419 45.4833C1.40291 46.231 1.90028 46.9125 2.49696 47.5012C3.08818 48.0974 3.77132 48.5949 4.52027 48.9745C5.26833 49.3549 6.06931 49.6207 6.89637 49.763C7.72465 49.8991 8.56195 49.973 9.40125 49.984C9.78761 49.9925 10.1672 49.9974 10.5499 49.9974C11.0071 49.9999 11.46 49.9999 11.9184 49.9999H137.668C138.118 49.9999 138.574 49.9999 139.023 49.9974C139.404 49.9974 139.795 49.9925 140.176 49.984C141.014 49.9736 141.849 49.8997 142.676 49.763C143.506 49.6197 144.309 49.354 145.061 48.9745C145.809 48.5947 146.492 48.0972 147.083 47.5012C147.678 46.9102 148.176 46.2292 148.56 45.4833C148.937 44.7338 149.198 43.9311 149.333 43.1029C149.473 42.2757 149.55 41.4393 149.565 40.6005C149.57 40.2123 149.57 39.8363 149.57 39.4482C149.58 38.994 149.58 38.5424 149.58 38.0809V11.9201C149.58 11.4623 149.58 11.0082 149.57 10.5553C149.57 10.172 149.57 9.78752 149.565 9.40422C149.55 8.56545 149.473 7.72896 149.333 6.90172C149.198 6.07402 148.937 5.2718 148.56 4.52257C147.788 3.01891 146.564 1.79495 145.061 1.02282C144.309 0.644378 143.505 0.380219 142.676 0.239068C141.849 0.101297 141.014 0.0274281 140.176 0.0181184C139.795 0.0120184 139.404 0.00469336 139.023 0.00225586C138.574 -0.000244141 138.118 -0.000244141 137.668 -0.000244141V-8.16406e-05Z"
						fill="#A6A6A6"
					/>
					<path
						d="M10.5561 48.9062C10.1752 48.9062 9.80358 48.9014 9.42572 48.8929C8.64295 48.8827 7.862 48.8145 7.0893 48.689C6.36878 48.5649 5.6708 48.3341 5.01837 48.0041C4.37192 47.6769 3.78231 47.2478 3.27212 46.7334C2.75455 46.225 2.32364 45.6353 1.99648 44.9878C1.66572 44.336 1.43681 43.6374 1.31773 42.9163C1.18914 42.1414 1.11956 41.3579 1.10961 40.5725C1.10168 40.3089 1.09131 39.4311 1.09131 39.4311V10.5554C1.09131 10.5554 1.10236 9.69116 1.10967 9.43725C1.1192 8.6531 1.18837 7.87082 1.31658 7.09716C1.43588 6.37406 1.66497 5.67344 1.99591 5.01954C2.32186 4.37243 2.75038 3.78233 3.26483 3.2721C3.77871 2.75703 4.37021 2.32576 5.01776 1.99401C5.66869 1.66512 6.36535 1.43592 7.08441 1.31409C7.85965 1.1873 8.64331 1.11875 9.4288 1.10901L10.5567 1.09375H139.017L140.159 1.10963C140.937 1.11887 141.714 1.18681 142.482 1.31286C143.208 1.43622 143.912 1.66702 144.57 1.99768C145.868 2.66623 146.923 3.72395 147.589 5.02259C147.915 5.67197 148.14 6.36688 148.258 7.08374C148.388 7.86374 148.46 8.65217 148.475 9.44275C148.479 9.79675 148.479 10.177 148.479 10.5554C148.489 11.0242 148.489 11.4703 148.489 11.9202V38.081C148.489 38.5351 148.489 38.9783 148.479 39.425C148.479 39.8315 148.479 40.2039 148.474 40.5871C148.46 41.3636 148.388 42.138 148.26 42.904C148.144 43.6303 147.916 44.3344 147.585 44.9915C147.256 45.632 146.828 46.2166 146.316 46.7236C145.805 47.2409 145.215 47.6724 144.567 48.0018C143.91 48.3342 143.207 48.5659 142.482 48.689C141.709 48.8152 140.928 48.8833 140.145 48.8929C139.779 48.9014 139.396 48.9062 139.024 48.9062L137.669 48.9087L10.5561 48.9062Z"
						fill="black"
					/>
					<path
						d="M30.9612 25.3757C30.9747 24.3323 31.2518 23.3094 31.7668 22.4019C32.2819 21.4945 33.0181 20.732 33.9069 20.1856C33.3423 19.3791 32.5973 18.7155 31.7313 18.2473C30.8653 17.7791 29.902 17.5193 28.918 17.4885C26.8189 17.2682 24.784 18.7445 23.7144 18.7445C22.624 18.7445 20.9772 17.5104 19.2037 17.5468C18.0566 17.5839 16.9387 17.9175 15.9589 18.515C14.979 19.1126 14.1707 19.9538 13.6126 20.9567C11.1951 25.1422 12.9983 31.2935 15.3141 34.6768C16.4727 36.3335 17.8268 38.184 19.5986 38.1184C21.3325 38.0465 21.98 37.0128 24.0729 37.0128C26.1463 37.0128 26.7539 38.1184 28.5616 38.0767C30.4221 38.0465 31.5943 36.4126 32.7123 34.7403C33.5447 33.5598 34.1853 32.2552 34.6103 30.8746C33.5294 30.4175 32.607 29.6522 31.9581 28.6744C31.3092 27.6965 30.9625 26.5492 30.9612 25.3757V25.3757Z"
						fill="white"
					/>
					<path
						d="M27.5467 15.2637C28.5611 14.0459 29.0609 12.4807 28.9399 10.9004C27.3901 11.0632 25.9585 11.8039 24.9304 12.9749C24.4277 13.547 24.0427 14.2125 23.7974 14.9335C23.5521 15.6545 23.4513 16.4167 23.5008 17.1766C24.2759 17.1846 25.0428 17.0166 25.7436 16.6852C26.4445 16.3539 27.061 15.8678 27.5467 15.2637Z"
						fill="white"
					/>
					<path
						d="M52.8782 33.9246H46.9614L45.5405 38.1202H43.0344L48.6387 22.5977H51.2424L56.8467 38.1202H54.2978L52.8782 33.9246ZM47.5742 31.9886H52.2642L49.9522 25.1795H49.8875L47.5742 31.9886Z"
						fill="white"
					/>
					<path
						d="M68.95 32.4621C68.95 35.9789 67.0677 38.2384 64.2271 38.2384C63.5075 38.2761 62.7919 38.1103 62.1622 37.7601C61.5324 37.41 61.014 36.8895 60.6663 36.2584H60.6125V41.8639H58.2896V26.8029H60.5381V28.6852H60.5808C60.9445 28.0571 61.4717 27.5394 62.1062 27.1871C62.7408 26.8348 63.459 26.6611 64.1844 26.6845C67.0566 26.6845 68.95 28.955 68.95 32.4621ZM66.5623 32.4621C66.5623 30.1708 65.3782 28.6644 63.5716 28.6644C61.7967 28.6644 60.6028 30.2025 60.6028 32.4621C60.6028 34.7423 61.7967 36.2694 63.5716 36.2694C65.3782 36.2694 66.5623 34.7741 66.5623 32.4621Z"
						fill="white"
					/>
					<path
						d="M81.406 32.462C81.406 35.9789 79.5237 38.2384 76.6831 38.2384C75.9636 38.2761 75.248 38.1103 74.6182 37.7601C73.9885 37.41 73.4701 36.8895 73.1224 36.2584H73.0686V41.8639H70.7456V26.8029H72.9941V28.6852H73.0369C73.4006 28.0571 73.9277 27.5394 74.5623 27.1871C75.1968 26.8348 75.915 26.6611 76.6404 26.6845C79.5127 26.6845 81.406 28.955 81.406 32.462ZM79.0183 32.462C79.0183 30.1708 77.8343 28.6644 76.0276 28.6644C74.2527 28.6644 73.0589 30.2025 73.0589 32.462C73.0589 34.7423 74.2527 36.2694 76.0276 36.2694C77.8343 36.2694 79.0184 34.7741 79.0184 32.462H79.0183Z"
						fill="white"
					/>
					<path
						d="M89.6385 33.7952C89.8106 35.3345 91.306 36.3452 93.3494 36.3452C95.3074 36.3452 96.7161 35.3345 96.7161 33.9465C96.7161 32.7417 95.8665 32.0203 93.8548 31.5259L91.8431 31.0413C88.9927 30.3528 87.6695 29.0198 87.6695 26.8567C87.6695 24.1785 90.0035 22.3389 93.3177 22.3389C96.5977 22.3389 98.8462 24.1785 98.9219 26.8567H96.5769C96.4365 25.3076 95.156 24.3726 93.2847 24.3726C91.4133 24.3726 90.1328 25.3186 90.1328 26.6956C90.1328 27.793 90.9507 28.4387 92.9514 28.9331L94.6616 29.353C97.8464 30.1062 99.1697 31.3855 99.1697 33.656C99.1697 36.56 96.8564 38.3789 93.1772 38.3789C89.7349 38.3789 87.4106 36.6027 87.2605 33.7951L89.6385 33.7952Z"
						fill="white"
					/>
					<path
						d="M104.183 24.1248V26.803H106.335V28.6426H104.183V34.8816C104.183 35.8508 104.614 36.3025 105.56 36.3025C105.816 36.298 106.071 36.2801 106.324 36.2487V38.0774C105.899 38.1568 105.467 38.1928 105.034 38.1848C102.743 38.1848 101.849 37.3242 101.849 35.1294V28.6426H100.204V26.803H101.849V24.1248H104.183Z"
						fill="white"
					/>
					<path
						d="M107.582 32.4622C107.582 28.9014 109.679 26.6638 112.949 26.6638C116.23 26.6638 118.318 28.9013 118.318 32.4622C118.318 36.0327 116.241 38.2605 112.949 38.2605C109.658 38.2605 107.582 36.0327 107.582 32.4622ZM115.951 32.4622C115.951 30.0195 114.831 28.5779 112.949 28.5779C111.067 28.5779 109.948 30.0305 109.948 32.4622C109.948 34.9145 111.067 36.3452 112.949 36.3452C114.831 36.3452 115.951 34.9145 115.951 32.4622H115.951Z"
						fill="white"
					/>
					<path
						d="M120.233 26.803H122.448V28.7293H122.502C122.652 28.1277 123.005 27.596 123.5 27.2236C123.996 26.8513 124.605 26.6609 125.224 26.6846C125.492 26.6836 125.759 26.7127 126.02 26.7713V28.9441C125.682 28.8408 125.33 28.7934 124.977 28.8037C124.639 28.79 124.303 28.8495 123.99 28.9782C123.678 29.1068 123.397 29.3014 123.167 29.5488C122.937 29.7962 122.764 30.0904 122.658 30.4112C122.553 30.732 122.518 31.0719 122.556 31.4075V38.1201H120.233L120.233 26.803Z"
						fill="white"
					/>
					<path
						d="M136.731 34.7961C136.418 36.8506 134.417 38.2605 131.858 38.2605C128.565 38.2605 126.522 36.0547 126.522 32.5159C126.522 28.9661 128.576 26.6638 131.76 26.6638C134.891 26.6638 136.86 28.8147 136.86 32.2461V33.042H128.867V33.1824C128.83 33.5989 128.882 34.0185 129.02 34.4133C129.158 34.8081 129.378 35.169 129.666 35.4722C129.954 35.7754 130.303 36.0139 130.69 36.1717C131.077 36.3296 131.494 36.4033 131.911 36.3879C132.46 36.4394 133.011 36.3122 133.482 36.0254C133.953 35.7386 134.319 35.3075 134.525 34.7961L136.731 34.7961ZM128.878 31.4185H134.536C134.557 31.044 134.5 30.6692 134.369 30.3177C134.238 29.9662 134.036 29.6455 133.776 29.3758C133.515 29.1061 133.201 28.8932 132.854 28.7504C132.508 28.6076 132.135 28.5381 131.76 28.5461C131.382 28.5439 131.007 28.6166 130.657 28.76C130.307 28.9034 129.988 29.1148 129.72 29.3818C129.453 29.6488 129.24 29.9663 129.096 30.3159C128.951 30.6654 128.877 31.0402 128.878 31.4185V31.4185Z"
						fill="white"
					/>
					<path
						d="M47.2827 10.9137C47.7697 10.8788 48.2584 10.9524 48.7136 11.1292C49.1687 11.3059 49.5789 11.5815 49.9147 11.9361C50.2504 12.2906 50.5033 12.7152 50.655 13.1793C50.8068 13.6434 50.8536 14.1354 50.7922 14.6198C50.7922 17.0026 49.5044 18.3723 47.2827 18.3723H44.5886V10.9137H47.2827ZM45.7471 17.3175H47.1533C47.5013 17.3383 47.8496 17.2812 48.1728 17.1505C48.4959 17.0197 48.7859 16.8185 49.0215 16.5616C49.2571 16.3046 49.4325 15.9983 49.5348 15.6651C49.6371 15.3318 49.6638 14.9799 49.613 14.635C49.6602 14.2914 49.6307 13.9417 49.5268 13.6109C49.4229 13.2801 49.2471 12.9763 49.0121 12.7214C48.777 12.4665 48.4884 12.2667 48.1671 12.1364C47.8457 12.0061 47.4995 11.9485 47.1533 11.9677H45.7471V17.3175Z"
						fill="white"
					/>
					<path
						d="M52.1009 15.5552C52.0655 15.1853 52.1079 14.8121 52.2252 14.4595C52.3425 14.107 52.5322 13.7828 52.7821 13.5078C53.032 13.2328 53.3367 13.0131 53.6765 12.8628C54.0163 12.7124 54.3838 12.6348 54.7554 12.6348C55.1269 12.6348 55.4944 12.7124 55.8342 12.8628C56.1741 13.0131 56.4787 13.2328 56.7286 13.5078C56.9785 13.7828 57.1682 14.107 57.2855 14.4595C57.4028 14.8121 57.4452 15.1853 57.4098 15.5552C57.4458 15.9255 57.404 16.2992 57.287 16.6524C57.17 17.0056 56.9805 17.3303 56.7305 17.6058C56.4805 17.8814 56.1756 18.1015 55.8355 18.2522C55.4953 18.4029 55.1274 18.4807 54.7554 18.4807C54.3833 18.4807 54.0154 18.4029 53.6753 18.2522C53.3351 18.1015 53.0302 17.8814 52.7802 17.6058C52.5303 17.3303 52.3407 17.0056 52.2237 16.6524C52.1067 16.2992 52.0649 15.9255 52.1009 15.5552V15.5552ZM56.2672 15.5552C56.2672 14.3352 55.7191 13.6217 54.7572 13.6217C53.7916 13.6217 53.2484 14.3352 53.2484 15.5553C53.2484 16.7851 53.7916 17.4931 54.7572 17.4931C55.7191 17.4931 56.2672 16.7802 56.2672 15.5552H56.2672Z"
						fill="white"
					/>
					<path
						d="M64.4665 18.3723H63.3142L62.1509 14.2268H62.063L60.9045 18.3723H59.7632L58.2117 12.7437H59.3384L60.3467 17.0387H60.4297L61.5869 12.7437H62.6526L63.8098 17.0387H63.8977L64.9011 12.7437H66.012L64.4665 18.3723Z"
						fill="white"
					/>
					<path
						d="M67.3169 12.7436H68.3862V13.6377H68.4692C68.6101 13.3166 68.8476 13.0474 69.1487 12.8676C69.4497 12.6879 69.7994 12.6065 70.1489 12.6349C70.4228 12.6143 70.6978 12.6556 70.9536 12.7558C71.2093 12.8559 71.4392 13.0123 71.6263 13.2133C71.8134 13.4144 71.9529 13.6549 72.0344 13.9172C72.1159 14.1795 72.1374 14.4567 72.0972 14.7284V18.3722H70.9863V15.0074C70.9863 14.1028 70.5933 13.653 69.7717 13.653C69.5858 13.6444 69.4002 13.676 69.2276 13.7458C69.055 13.8156 68.8996 13.9219 68.7719 14.0573C68.6443 14.1928 68.5474 14.3543 68.488 14.5307C68.4286 14.7071 68.408 14.8943 68.4277 15.0794V18.3722H67.3169L67.3169 12.7436Z"
						fill="white"
					/>
					<path d="M73.8672 10.5464H74.978V18.3723H73.8672V10.5464Z" fill="white" />
					<path
						d="M76.5223 15.5553C76.4869 15.1854 76.5293 14.8122 76.6466 14.4596C76.7639 14.107 76.9537 13.7828 77.2036 13.5078C77.4536 13.2328 77.7582 13.0131 78.0981 12.8628C78.4379 12.7124 78.8054 12.6348 79.177 12.6348C79.5486 12.6348 79.9161 12.7124 80.256 12.8628C80.5958 13.0131 80.9005 13.2328 81.1504 13.5078C81.4004 13.7828 81.5901 14.107 81.7074 14.4596C81.8248 14.8122 81.8671 15.1854 81.8317 15.5553C81.8678 15.9256 81.8259 16.2994 81.7089 16.6525C81.5919 17.0057 81.4022 17.3304 81.1522 17.606C80.9022 17.8815 80.5973 18.1016 80.2571 18.2523C79.917 18.4029 79.5491 18.4808 79.177 18.4808C78.805 18.4808 78.4371 18.4029 78.0969 18.2523C77.7567 18.1016 77.4518 17.8815 77.2018 17.606C76.9518 17.3304 76.7622 17.0057 76.6452 16.6525C76.5281 16.2994 76.4863 15.9256 76.5223 15.5553V15.5553ZM80.6885 15.5553C80.6885 14.3353 80.1404 13.6218 79.1785 13.6218C78.213 13.6218 77.6698 14.3353 77.6698 15.5554C77.6698 16.7852 78.213 17.4932 79.1785 17.4932C80.1405 17.4932 80.6886 16.7803 80.6886 15.5553H80.6885Z"
						fill="white"
					/>
					<path
						d="M83.001 16.7803C83.001 15.7671 83.7554 15.183 85.0945 15.1L86.6191 15.0121V14.5262C86.6191 13.9318 86.2261 13.5961 85.4668 13.5961C84.8467 13.5961 84.417 13.8237 84.2937 14.2217H83.2183C83.3318 13.2549 84.2412 12.6348 85.5181 12.6348C86.9292 12.6348 87.7251 13.3373 87.7251 14.5262V18.3721H86.6558V17.5811H86.5679C86.3895 17.8648 86.139 18.0961 85.8419 18.2514C85.5449 18.4066 85.212 18.4803 84.8772 18.4648C84.6409 18.4894 84.4021 18.4642 84.1761 18.3908C83.9502 18.3174 83.7421 18.1975 83.5653 18.0388C83.3886 17.8801 83.247 17.6861 83.1498 17.4693C83.0526 17.2526 83.0019 17.0178 83.001 16.7803V16.7803ZM86.6191 16.2993V15.8287L85.2446 15.9166C84.4695 15.9685 84.1179 16.2322 84.1179 16.7284C84.1179 17.235 84.5574 17.5298 85.1617 17.5298C85.3387 17.5477 85.5176 17.5298 85.6876 17.4772C85.8576 17.4246 86.0153 17.3383 86.1512 17.2235C86.2872 17.1087 86.3987 16.9677 86.4791 16.809C86.5595 16.6502 86.6071 16.4769 86.6191 16.2993V16.2993Z"
						fill="white"
					/>
					<path
						d="M89.1851 15.5556C89.1851 13.777 90.0994 12.6503 91.5215 12.6503C91.8733 12.6341 92.2224 12.7183 92.528 12.8932C92.8337 13.0681 93.0833 13.3263 93.2476 13.6378H93.3306V10.5464H94.4414V18.3723H93.377V17.483H93.2891C93.1121 17.7924 92.8538 18.0474 92.5422 18.2205C92.2307 18.3936 91.8777 18.4782 91.5215 18.4651C90.0896 18.4651 89.1851 17.3384 89.1851 15.5556ZM90.3326 15.5556C90.3326 16.7494 90.8953 17.4678 91.8365 17.4678C92.7727 17.4678 93.3514 16.739 93.3514 15.5604C93.3514 14.3873 92.7666 13.6482 91.8365 13.6482C90.9014 13.6482 90.3325 14.3715 90.3325 15.5556H90.3326Z"
						fill="white"
					/>
					<path
						d="M99.0375 15.5552C99.0021 15.1853 99.0444 14.8121 99.1617 14.4595C99.279 14.107 99.4687 13.7828 99.7186 13.5078C99.9685 13.2328 100.273 13.0131 100.613 12.8628C100.953 12.7124 101.32 12.6348 101.692 12.6348C102.063 12.6348 102.431 12.7124 102.771 12.8628C103.111 13.0131 103.415 13.2328 103.665 13.5078C103.915 13.7828 104.105 14.107 104.222 14.4595C104.339 14.8121 104.382 15.1853 104.346 15.5552C104.382 15.9255 104.341 16.2992 104.224 16.6524C104.107 17.0056 103.917 17.3303 103.667 17.6058C103.417 17.8814 103.112 18.1015 102.772 18.2522C102.432 18.4029 102.064 18.4807 101.692 18.4807C101.32 18.4807 100.952 18.4029 100.612 18.2522C100.272 18.1015 99.9668 17.8814 99.7168 17.6058C99.4668 17.3303 99.2772 17.0056 99.1602 16.6524C99.0432 16.2992 99.0014 15.9255 99.0375 15.5552V15.5552ZM103.204 15.5552C103.204 14.3352 102.656 13.6217 101.694 13.6217C100.728 13.6217 100.185 14.3352 100.185 15.5553C100.185 16.7851 100.728 17.4931 101.694 17.4931C102.656 17.4931 103.204 16.7802 103.204 15.5552Z"
						fill="white"
					/>
					<path
						d="M105.837 12.7436H106.906V13.6377H106.989C107.13 13.3166 107.368 13.0474 107.669 12.8676C107.97 12.6879 108.319 12.6065 108.669 12.6349C108.943 12.6143 109.218 12.6556 109.474 12.7558C109.729 12.8559 109.959 13.0123 110.146 13.2133C110.333 13.4144 110.473 13.6549 110.554 13.9172C110.636 14.1795 110.657 14.4567 110.617 14.7284V18.3722H109.506V15.0074C109.506 14.1028 109.113 13.653 108.292 13.653C108.106 13.6444 107.92 13.676 107.748 13.7458C107.575 13.8156 107.42 13.9219 107.292 14.0573C107.164 14.1928 107.067 14.3543 107.008 14.5307C106.949 14.7071 106.928 14.8943 106.948 15.0794V18.3722H105.837V12.7436Z"
						fill="white"
					/>
					<path
						d="M116.894 11.3423V12.7693H118.113V13.705H116.894V16.5992C116.894 17.1888 117.137 17.447 117.69 17.447C117.831 17.4466 117.973 17.438 118.113 17.4214V18.3467C117.914 18.3824 117.712 18.4013 117.509 18.4034C116.274 18.4034 115.782 17.9689 115.782 16.8837V13.7049H114.888V12.7692H115.782V11.3423H116.894Z"
						fill="white"
					/>
					<path
						d="M119.631 10.5464H120.732V13.6482H120.82C120.967 13.324 121.212 13.0533 121.519 12.8729C121.826 12.6924 122.181 12.6111 122.536 12.6399C122.809 12.6251 123.081 12.6706 123.334 12.7731C123.587 12.8757 123.814 13.0328 123.999 13.2332C124.184 13.4336 124.323 13.6724 124.405 13.9326C124.487 14.1928 124.511 14.4679 124.475 14.7383V18.3723H123.363V15.0123C123.363 14.1133 122.944 13.6579 122.159 13.6579C121.968 13.6423 121.776 13.6685 121.596 13.7349C121.417 13.8012 121.254 13.906 121.119 14.0419C120.984 14.1778 120.88 14.3416 120.815 14.5217C120.75 14.7019 120.725 14.8941 120.742 15.0849V18.3723H119.631L119.631 10.5464Z"
						fill="white"
					/>
					<path
						d="M130.951 16.8524C130.801 17.3668 130.474 17.8118 130.028 18.1095C129.582 18.4072 129.046 18.5386 128.513 18.4808C128.142 18.4906 127.773 18.4196 127.433 18.2726C127.092 18.1257 126.787 17.9065 126.54 17.63C126.293 17.3536 126.108 17.0267 126 16.6719C125.892 16.3171 125.862 15.943 125.912 15.5755C125.863 15.2069 125.893 14.832 126.002 14.4762C126.11 14.1203 126.293 13.7919 126.539 13.513C126.785 13.2342 127.088 13.0115 127.428 12.86C127.768 12.7085 128.136 12.6317 128.508 12.6349C130.074 12.6349 131.019 13.7049 131.019 15.4724V15.86H127.044V15.9222C127.027 16.1288 127.053 16.3367 127.12 16.5326C127.188 16.7286 127.296 16.9082 127.437 17.06C127.578 17.2118 127.749 17.3325 127.94 17.4142C128.13 17.496 128.336 17.537 128.543 17.5347C128.809 17.5666 129.078 17.5188 129.316 17.3973C129.555 17.2758 129.752 17.0861 129.882 16.8523L130.951 16.8524ZM127.044 15.0384H129.887C129.901 14.8495 129.875 14.6598 129.812 14.4814C129.748 14.303 129.648 14.1399 129.517 14.0025C129.387 13.8652 129.229 13.7567 129.054 13.684C128.879 13.6113 128.691 13.576 128.502 13.5803C128.309 13.5779 128.119 13.614 127.941 13.6864C127.763 13.7588 127.601 13.8661 127.465 14.002C127.329 14.1379 127.222 14.2996 127.15 14.4776C127.078 14.6556 127.042 14.8463 127.044 15.0384H127.044Z"
						fill="white"
					/>
				</g>
				<defs>
					<clipPath id="clip0_456_1599">
						<rect width="149.58" height={50} fill="white" />
					</clipPath>
				</defs>
			</svg>
		</div>
	);
}
