import React from 'react';
import {VSymbol} from '../../components';

export default function EmailVerifiedRender():JSX.Element{
	return (
		<div className='grows flex direction-column jus w-100 justify-center align-center'>
			<VSymbol/>
			<h2 className='mt center'>Your email has been verified</h2>
		</div>
	);
}
