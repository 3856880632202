import React from 'react';
// @ts-ignore
import {Message} from '@moadigitalworks/boutique-app/dist/components/ui';
import {Popup} from 'portals/Popup';
import {FormButton} from 'components/ui';
import {FormProps} from 'interfaces';
import {CloseIcon} from 'components/icons';
import {MultipleButton} from 'components/ui/MultipleButton';
import {color} from 'styles/colors';


interface PopupRenderProps extends FormProps{
	formRef:any
	total:number
	setTip:(num:number)=>void
	hidePopup:()=>void
}


export default function ConfirmPickUpRender({formRef,errors,total,hidePopup,setTip,onSubmit}:PopupRenderProps):JSX.Element {
	return (
		<Popup >
			<div
				id={'wtf'}
				className='grows w-100 flex direction-column align-center justify-center pver-2 phor'
				style={{backgroundColor:'rgba(0,0,0,0.8)'}}
			>
				<div
					ref={formRef}
					style={{alignSelf:'center'}}
					className=' white t-black br max-w-300px'
				>
					<div className='flex justify-end mver mhor'>
						<CloseIcon action={hidePopup}/>
					</div>
					<form
						className='w-100 flex direction-column align-center pt-3 pr pb-2 pl'
						onSubmit={onSubmit}
					>

						<h4 className='f-large mb'>Confirm Pickup</h4>
						<p className='f-300 center mb-3'>
							The valet will pickup your car and deliver it to your location.
						</p>

						<div className={'f-uppercase mb center f-700'}>
							<p className='t-grey400 f-300'>subtotal</p>
							<p>$18.99</p>
						</div>

						<div className={'f-uppercase mb-2 f-700'}>
							<p className='t-grey400 f-300'>Add a tip for the valet</p>
							<MultipleButton
								className={'w-100 grid3 col-gap'}
								onChange={setTip}
								activeColor={color.get('green-valay')}
								defaultValue={5}
								options = {[
									{label:'$5',value:5},
									{label:'$10',value:10},
									{label:'$20',value:20}
								]}
							/>
						</div>

						<div className={'f-uppercase center f-700'}>
							<p className='t-grey400 f-300'>TOTAL</p>
							<p className='f-large'>${total}</p>
						</div>

						<FormButton
							className='w-100'
						>
							Pay Order
						</FormButton >
						{errors?<div className='mt'>
							{errors.address?.required && <Message message='Address is required' color='red'/>}
							{errors.valet?.pattern && <Message message='Please add a valid email' color='red'/>}
							{errors.submitError && <Message message={errors.submitError} color='red'/>}
						</div>: null}
					</form>
				</div>
			</div>
		</Popup>
	);
}
