import React from 'react';
import {AppleStoreButton} from '../../ui';
import {MobileHeader} from '../../Header/MobileHeader';
import {Link} from 'react-router-dom';

export interface LayoutProps {
	deliveryCode?:number
	children: React.ReactNode
}

export default function MobileLayoutRender({deliveryCode,children}:LayoutProps):JSX.Element{
	return (
		<>
			<MobileHeader/>

			<main className='white w-100 grows grid1 t-black '>
				{deliveryCode&&
				<div className={'w-100'}>
					<div
						style={{height: '36px'}}
						className={`w-100 flex teal-valet align-center justify-center f-700`}
					>
						<p>DELIVERY CODE: {deliveryCode}</p>
					</div>
					<p className='t-grey500 f-small f-300 center'>SHOW THIS CODE TO THE VALET ON CAR DELIVERY</p>
				</div>
				}
				<section className={'p pb-3'}>
					<>{children}</>
				</section>
				<div className=' align-self-end grid1 phor-3 pb-3' style={{justifyItems:'center'}} >
					<p className='max-w-300px f-small f-300 center mb'>
						Manage your reservations and all your profile from the Valay App available on the app store
					</p>
					<Link target='_blank' referrerPolicy='no-referrer' to={'https://apps.apple.com/us/app/valay/id1082252049'}>
						<AppleStoreButton/>
					</Link>
				</div>
			</main>
		</>
	);
}
