import {ApolloClient, createHttpLink, InMemoryCache, ApolloLink} from '@apollo/client';
import {addHeaders} from '../tools';

const link:ApolloLink = createHttpLink({
	uri:  process.env.REACT_APP_GRAPHQL,
	credentials:'include',
	headers:addHeaders
});

export const client =():ApolloClient<any>=> new ApolloClient({
	cache: new InMemoryCache(),
	link
});
