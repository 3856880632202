import React from 'react';
import MobileHeaderRender from './MobileHeaderRender';
import {NavigateFunction, useLocation, useNavigate} from 'react-router-dom';
import {logout} from '../../../actions/restActions';


export const MobileHeader = ():JSX.Element=>{
	const {pathname}:any = useLocation();
	const navigate:NavigateFunction=useNavigate();
	let pathArray: Array<string> = pathname.split('/');
	let path:string = pathArray[1];
	if (pathArray.length>2 && pathArray.includes('reservations'))
		path = 'reservation-detail';

	const navigationByPath : any={
		'reservations': {
			path:'',
			label:'My Reservations'
		},
		'reservation-detail': {
			path:'/reservations',
			label:'My Reservations'
		}
	};

	let logoutAction =():void=>{
		console.log('logout');
		logout().then(():void=>navigate('/'));
	};
	return <MobileHeaderRender
		navigate={navigationByPath[path]}
		logoutAction={logoutAction}
	/>;
};
