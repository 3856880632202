import React, {useState} from 'react';
import MultipleButtonRender, {MultiButtonRenderProps} from './MultipleButtonRender';

interface MultiButtonProps extends MultiButtonRenderProps {
	defaultValue:string|number|boolean
	onChange : ((value:number)=>void)|((value:string)=>void )| ((value:boolean)=>void)
}
export const MultipleButton =({defaultValue,...props}:MultiButtonProps):JSX.Element=>{
	const [value,setValue]:any=useState(defaultValue);

	return <MultipleButtonRender value={value} {...props}/>;
};
