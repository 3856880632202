import React from 'react';
import {BackArrow} from '../../icons/';
import {Link} from 'react-router-dom';

interface SMSHeaderRenderProps {
    navigate:{
		path:string
		label:string
	}
	logoutAction:()=>void
}

export default function MobileHeaderRender({navigate,logoutAction}:SMSHeaderRenderProps):JSX.Element{
	return (
		<div className='w-100 max-w-1600px flex justify-between align-center h-80px phor-2' >
			<div className='flex align-center'>
				{navigate.path &&
					<Link to={navigate.path}>
						<BackArrow/>
					</Link>
				}
				<p className='ml'>{navigate.label}</p>
			</div>
			<p onClick={logoutAction}>Logout</p>
		</div>
	);
}
