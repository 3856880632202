import React, {useContext, useEffect, useState} from 'react';
import SetUpPWValetRender from './SetUpPWValetRender';
import {FormField, FormHook, useForm} from 'hooks/useForm';
import {NavigateFunction, Params, useNavigate, useParams} from 'react-router-dom';
import {UserContext} from 'context/userContext';
import {getResetPasswordByToken} from 'actions/restActions';
import {AxiosResponse} from 'axios';


export const SetUpPWValet = ():JSX.Element=>{
	const {token}:Params = useParams();
	const navigate:NavigateFunction=useNavigate();
	const [email,setEmail]:any = useState('');
	const [submitError,setSubmitError]:any = useState('');
	const {setUpPassword}:any=useContext(UserContext);
	const initForm:Array<FormField>  = [
		{
			name:'password',
			default: '',
			required: true
		}
	];

	const SetUpPassword=async(data:any):Promise<any> =>{
		data.token=token;
		console.log('Set password', {data});
		try {
			console.log('--reset');
			await setUpPassword(data);
			console.log('--navigate');
			navigate('/reservations');
		}
		catch (e) {
			setSubmitError(e||'Error on Login');
		}

	};

	const {errors,handleSubmitForm,onChangeInput,loading}:FormHook=useForm(initForm, SetUpPassword);

	useEffect(():void=>{
		console.log({token});
		getResetPasswordByToken(token||'')
		.then((res:AxiosResponse):void=>setEmail(res.data?.result))
		.catch((err:Error):void=>setSubmitError('Token no valid',{err}));
	},[]);

	return <SetUpPWValetRender
		email={email}
		submitLoading={loading}
		errors={{...errors,submitError}}
		onChangeInput={onChangeInput}
		onSubmit={handleSubmitForm}
	/>;
};
